<template>
    <aside class="main-sidebar" :class="{ 'collapse': menuCollapsed }">
        <fa-icon v-if="!menuCollapsed" :icon="['far', 'chevron-left']" class="collapse-btn" @click="toggleCollapse(true)" />
        <fa-icon v-else :icon="['far', 'chevron-right']" class="collapse-btn" @click="toggleCollapse(false)" />

        <div v-if="! menuCollapsed" class="logo-container" :class="{'collapse': menuCollapsed}">
            <img src="@/assets/images/logo_bw.png" class="self-center mx-auto mt-4 mb-1" width="120px" alt="">
            
        </div>

        <p v-if="! menuCollapsed" class="flex justify-center mb-4 text-white text-xs px-3">
            {{ $store.state.user.user.welcome_title }}
        </p>

        <div v-bar="{ preventParentScroll: true }" :class="{ 'collapse': menuCollapsed }">
            <el-menu
                mode="vertical"
                class="z-10 sidebar-menu"
                :router="true"
                :default-active="activeMenu"
                :collapse="menuCollapsed"
                :unique-opened="true"
                background-color="#00000000"
                text-color="#eee"
                active-text-color="#f26364"
            >
                <el-menu-item v-if="userCan('read dashboard')" :route="{ name: 'dashboard' }" index="20">
                    <fa-icon :icon="['fas', 'chart-network']" fixed-width class="text-lg" />
                    <span class="pl-4 text-xs">{{ $t('sidebar.dashboard') }}</span>
                </el-menu-item>
<!-- 
                <el-menu-item v-if="userCan('read calendar events')" :route="{ name: 'calendar' }" index="2">
                    <fa-icon :icon="['fas', 'calendar-alt']" fixed-width class="text-lg" />
                    <span class="pl-4 text-xs">{{ $t('sidebar.calendar') }}</span>
                </el-menu-item>
 -->
                <!-- <el-menu-item v-if="userCan('read map')" :route="{ name: 'map' }" index="0">
                    <fa-icon :icon="['fas', 'map']" fixed-width class="text-lg" />
                    <span class="pl-4 text-xs">{{ $t('sidebar.map') }}</span>
                </el-menu-item> -->
<!-- 
                <el-submenu
                    v-if="userCan('read clients') || userCan('create clients') || userCan('read keys') || userCan('read service history')"
                    index="1"
                    :show-timeout="0"
                >
                    <template slot="title">
                        <fa-icon :icon="['fas', 'users']" fixed-width class="text-xl mr-4" />
                        <span slot="title" class="text-xs">{{ $t('sidebar.clients') }}</span>
                    </template>
                    <el-menu-item v-if="userCan('read clients')" :route="{ name: 'clients_all' }" index="1-1">
                        <span class="pl-5 text-xs">{{ $t('sidebar.all_clients') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('create clients')" :route="{ name: 'clients_create'}" index="1-2">
                        <span class="pl-5 text-xs">{{ $t('sidebar.add_new') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('read keys')" :route="{ name: 'keys'}" index="1-3">
                        <span class="pl-5 text-xs">{{ $t('sidebar.keys') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('read service history')" :route="{ name: 'clients_history'}" index="1-4">
                        <span class="pl-5 text-xs">{{ $t('sidebar.service_history') }}</span>
                    </el-menu-item>
                    <el-menu-item :route="{ name: 'clients_damages'}" index="1-5">
                        <span class="pl-5 text-xs">{{ $t('sidebar.damages') }}</span>
                    </el-menu-item>
                </el-submenu>
-->
<!-- 
                <el-submenu
                    v-if="userCan('read employees') || userCan('create employees') || userCan('read service history')"
                    index="3"
                    :show-timeout="0"
                >
                    <template slot="title">
                        <fa-icon :icon="['fas', 'user-cog']" fixed-width class="text-xl mr-4" />
                        <span slot="title" class="text-xs">{{ $t('sidebar.employees') }}</span>
                    </template>
                    <el-menu-item v-if="userCan('read employees')" :route="{ name: 'employees_all'}" index="3-1">
                        <span class="pl-5 text-xs">{{ $t('sidebar.all_employees') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('create employees')" :route="{ name: 'employees_create'}" index="3-2">
                        <span class="pl-5 text-xs">{{ $t('sidebar.add_new') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('read service history')" :route="{ name: 'employees_history'}" index="3-5">
                        <span class="pl-5 text-xs">{{ $t('sidebar.service_history') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('read service history')" :route="{ name: 'employees_failure_check_in'}" index="3-7">
                        <span class="pl-5 text-xs">{{ $t('sidebar.failure_checkin') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('read absence')" :route="{ name: 'employees_absence'}" index="3-6">
                        <span class="pl-5 text-xs">{{ $t('sidebar.absence') }}</span>
                    </el-menu-item>
                </el-submenu>
 -->
                <!-- <el-submenu v-if="userCan('read managers') || userCan('create managers')" index="4" :show-timeout="0">
                    <template slot="title">
                        <fa-icon :icon="['fas', 'user-plus']" fixed-width class="text-xl mr-4" />
                        <span slot="title" class="text-xs">{{ $t('sidebar.managers') }}</span>
                    </template>
                    <el-menu-item v-if="userCan('read managers')" :route="{ name: 'managers_all'}" index="4-1">
                        <span class="pl-5 text-xs">{{ $t('sidebar.all_managers') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('create managers')" :route="{ name: 'managers_create'}" index="4-2">
                        <span class="pl-5 text-xs">{{ $t('sidebar.add_new') }}</span>
                    </el-menu-item>
                </el-submenu> -->
<!-- 
                <el-submenu v-if="userCan('read services') || userCan('read service categories')" index="5" :show-timeout="0">
                    <template slot="title">
                        <fa-icon :icon="['fas', 'broom']" fixed-width class="text-xl mr-4" />
                        <span slot="title" class="text-xs">{{ $t('sidebar.services') }}</span>
                    </template>
                    <el-menu-item v-if="userCan('read services')" :route="{ name: 'services'}" index="5-1">
                        <span class="pl-5 text-xs">{{ $t('sidebar.services') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('read service categories')" :route="{ name: 'service_categories'}" index="5-2">
                        <span class="pl-5 text-xs">{{ $t('sidebar.categories') }}</span>
                    </el-menu-item>
                </el-submenu>
 -->
                <!-- <el-menu-item v-if="userCan('read cars')" :route="{ name: 'cars_all' }" index="12">
                    <fa-icon :icon="['fas', 'car']" fixed-width class="text-lg" />
                    <span class="pl-4 text-xs">{{ $t('sidebar.cars') }}</span>
                </el-menu-item> -->

                <!-- <el-submenu v-if="userCan('read web integrations')" index="11" :show-timeout="0">
                    <template slot="title">
                        <fa-icon :icon="['fas', 'shopping-cart']" fixed-width class="text-xl mr-4" />
                        <span slot="title" class="text-xs">{{ $t('sidebar.sales') }}</span>
                    </template>
                    <el-menu-item :route="{ name: 'website_requests'}" index="11-1">
                        <span class="pl-5 text-xs">{{ $t('sidebar.website_requests') }}</span>
                    </el-menu-item>
                </el-submenu> -->
                <el-menu-item v-if="userCan('read invoices')" :route="{ name: 'invoices' }" index="6">
                    <fa-icon :icon="['fas', 'chart-line']" fixed-width class="text-lg" />
                        <span class="pl-5 text-xs">{{ $t('sidebar.invoices') }}</span>
                </el-menu-item>

                <!-- <el-submenu v-if="userCan('read invoices') || userCan('read salaries')" index="6" :show-timeout="0">
                    <template slot="title">
                        <fa-icon :icon="['fas', 'chart-line']" fixed-width class="text-xl mr-4" />
                        <span slot="title" class="text-xs">{{ $t('sidebar.economy') }}</span>
                    </template>
                    <el-menu-item v-if="userCan('read invoices')" :route="{ name: 'invoices'}" index="6-1">
                        <span class="pl-5 text-xs">{{ $t('sidebar.invoices') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('read salaries')" :route="{ name: 'salaries'}" index="6-2">
                        <span class="pl-5 text-xs">{{ $t('sidebar.salaries') }}</span>
                    </el-menu-item>
                    <el-menu-item :route="{ name: 'autogiro'}" index="6-3" v-if="userCan('read autogiro')">
                    <el-menu-item :route="{ name: 'autogiro'}" index="6-3">
                        <span class="pl-5 text-xs">Autogiro</span>
                    </el-menu-item>
                    <el-menu-item v-if="userIsCleaningCompany() && userCan('read rut files')" :route="{ name: 'rut_files'}" index="6-4">
                        <span class="pl-5 text-xs">{{ $t('sidebar.rut_files') }}</span>
                    </el-menu-item>
                </el-submenu>
                -->

                <!-- <el-submenu v-if="userCan('read emails') || userCan('read chat')" index="7" :show-timeout="0">
                    <template slot="title">
                        <span v-if="unreadMessages" class="message-badge absolute left-[45px] top-[5px] flex rounded-full h-2 w-2 bg-red-600" />
                        <fa-icon :icon="['fas', 'comment-dots']" fixed-width class="text-xl mr-4" />
                        <span slot="title" class="text-xs">{{ $t('sidebar.communication') }}</span>
                    </template>
                    <el-menu-item v-if="userCan('read emails')" :route="{ name: 'communication_emails' }" index="7-1">
                        <span class="pl-5 text-xs">{{ $t('sidebar.emails') }}</span>
                    </el-menu-item>
                    <el-menu-item v-if="userCan('read chat')" :route="{ name: 'messages' }" index="7-2">
                        <span v-if="unreadMessages" class="absolute top-1 right-[10px] flex rounded-full h-2 w-2 bg-red-600" />
                        <span class="pl-5 text-xs">{{ $t('sidebar.messages') }}</span>
                    </el-menu-item>
                </el-submenu> -->

                <!-- <el-menu-item v-if="userCan('read statistics')" :route="{ name: 'statistics' }" index="9">
                    <fa-icon :icon="['fas', 'chart-pie']" fixed-width class="text-lg" />
                    <span class="pl-4 text-xs">{{ $t('sidebar.statistics') }}</span>
                </el-menu-item> -->

                <!-- <el-menu-item v-if="userCan('read help desk')" :route="{ name: 'help_desk' }" index="10">
                    <fa-icon :icon="['fas', 'info-circle']" fixed-width class="text-lg" />
                    <span class="pl-4 text-xs">{{ $t('sidebar.help_desk') }}</span>
                </el-menu-item> -->

                <!-- <el-submenu v-if="userCan('read settings')" index="99" :show-timeout="0">
                    <template slot="title">
                        <fa-icon :icon="['fas', 'cog']" fixed-width class="text-lg mr-4" />
                        <span slot="title" class="text-xs">{{ $t('sidebar.settings') }}</span>
                    </template>
                    <el-menu-item :route="{ name:'settings_general' }" index="99-1">
                        <span class="pl-5 text-xs">{{ $t('sidebar.general') }}</span>
                    </el-menu-item>
                    <el-menu-item :route="{ name:'settings_economy_general' }" index="99-3">
                        <span class="pl-5 text-xs">{{ $t('sidebar.economy') }}</span>
                    </el-menu-item>
                    <el-menu-item @click="openStripeWindow">
                        <span class="pl-5 text-xs">{{ $t('sidebar.billing') }}</span>
                    </el-menu-item>
                    <el-menu-item :route="{ name:'settings_roles_and_permissions' }" index="99-4">
                        <span class="pl-5 text-xs">{{ $t('sidebar.roles_and_permissions') }}</span>
                    </el-menu-item>
                </el-submenu> -->
            </el-menu>
        </div>
    </aside>
</template>
<script>
import Vuebar from 'vuebar';
import Vue from 'vue';
import './vbar.scss';
import {
    Menu, MenuItem, MenuItemGroup, Submenu,
} from 'element-ui';

Vue.use(Vuebar);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Submenu);

export default {
    computed: {
        menuCollapsed() {
            if (window.innerWidth < 600) {
                return true;
            }
            return this.$store.state.menuCollapsed;
        },

        activeMenu() {
            return this.$store.state.activeMenu;
        },

        userType() {
            return this.$store.state.user.user.type;
        },

    },

    methods: {
        toggleCollapse() {
            this.$store.commit('toggleMenuStatue');
        },
    },
};
</script>
<style>
.main-sidebar {
    width: 229px;
    background-color: #232323;
    position: fixed;
    height: 100%;
    box-shadow: 0px 1px 4px 1px #0000008c;
}

.el-menu,
.el-submenu {
    border-right: none;
}
.el-menu .el-menu-item:hover,
.el-submenu .el-submenu__title:hover {
    background-color: #333 !important;
}
.el-menu.is-active .el-submenu__title {
    color: #f26364 !important;
}
.el-submenu.is-active .el-submenu__title {
    color: #f26364 !important;
}
.el-menu .el-menu-item, .el-menu .el-submenu__title {
    height: 40px;
    line-height: 40px;
}

.vb.collapse {
    height: calc(100% - 10px);
}

.ps__rail-y {
    z-index: 999;
}
.ps {
  height: 400px;
}


.main-sidebar .collapse-btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px !important;
    color: #ddd;
    background: #444444;
    border-radius: 0 0 0 7px;
    height: 30px;
    width: 20px;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    z-index: 100;
    transition: 250ms ease background-color;
}

.main-sidebar .collapse-btn:hover {
    background-color: #555;
}

.main-sidebar.collapse {
    width: 60px;
    padding-top: 35px;
}

.main-sidebar.collapse .message-badge{
    height: 10px !important;
    width: 10px !important;
    visibility: visible !important;
}

.el-menu--vertical {
    background: #fff;
}

.el-menu--vertical .el-menu-item {
    color: #333 !important;
}

.el-menu--vertical .el-menu-item:hover {
    background: #eee !important;
}
</style>
